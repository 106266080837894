@import 'src/styles/one-design/variables';

// Generic styles

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.hidden {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.word-wrap {
  word-break: break-word; // fix the wrap of question mark on chrome
  overflow-wrap: break-word;
}

.truncate-text {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.p-relative {
  position: relative;
}

.base-font-enforced {
  font-size: $cair-base-font !important;
}

// Global link disabled class style
a {
  &.disabled-link {
    color: $one-color-gray-500 !important;
    cursor: not-allowed;
    text-decoration: none !important;
    opacity: 0.8;

    &:hover,
    &:focus {
      color: $one-color-gray-500 !important;
    }
  }
}

app-variant-pin-icon mat-icon {
  height: $one-text-font-size-px-16;
  width: $one-text-font-size-px-16;
  max-width: 100%;
  max-height: 100%;
}

.owc-caption {
  font-size: $one-text-font-size-px-12;
  font-weight: $one-text-font-weight-400;
  letter-spacing: normal;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-gap-10 {
  gap: 10px;
}

.gap-20-p {
  gap: $one-spacer-20;
}

.gap-0-p {
  gap: $one-spacer-0;
}
