@media print {
  @page {
    // Add top margin
    // Bottom margin will be set dynamically (see report-page.component.ts)
    margin: 0.5cm 0 2.5cm 0;
  }

  // Added in case a notification or modal set body overflow to hidden.
  body {
    overflow: auto !important;
  }

  .page-break-before {
    page-break-before: always;
  }

  .page-break-after {
    page-break-after: always;
  }

  .no-page-break {
    page-break-inside: avoid;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  #onetrust-consent-sdk {
    display: none;
  }
}

app-variant-content a {
  color: $one-color-gray-700;
  text-decoration: underline;
}
