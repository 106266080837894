$spinner-radius: 24px;
$spinner-circumference: 2 * 3.14 * $spinner-radius;
$spinner-padding-space: ($spinner-radius + 1);

.material-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  /* having style="display:none" avoids quirks before the styles are loaded */
  display: flex !important;
  align-content: center;
  align-self: center;
  justify-content: center;
}

.material-spinner {
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: center;
  animation: rotate 2s linear infinite;
  circle {
    fill: none;
    stroke: $one-color-blue-900;
    stroke-dasharray: 2, $spinner-circumference;
    stroke-dashoffset: 0;
    animation: dash 1.35s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 2, $spinner-circumference;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: $spinner-circumference - 2 * $spinner-padding-space, $spinner-padding-space;
    stroke-dashoffset: -$spinner-padding-space;
  }
  100% {
    stroke-dasharray: 2, $spinner-circumference;
    stroke-dashoffset: -$spinner-circumference;
  }
}

.owc-loading-spinner {
  padding: $one-dimension-4x $one-spacer-0;
  box-sizing: border-box;
  text-align: center;
}
