owc-card {
  .card-content {
    font-size: $one-text-font-size-px-14;

    owc-slide-toggle > * {
      font-size: $one-text-font-size-px-14;
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    gap: $one-dimension-2x;
  }
}
