// Element specific styling

@import 'src/styles/one-design/variables';

// <hr>
hr.full-width {
  display: block;
  border-top: 1px solid $one-color-gray-200;
  border-left: 1px solid $one-color-gray-200;
  width: 100%;
  box-sizing: border-box;
}

hr.full-width-light {
  display: block;
  border-top: 1px solid $one-color-gray-50;
  border-left: 1px solid $one-color-gray-50;
  width: 100%;
  box-sizing: border-box;
}

hr.full-width-green {
  display: block;
  border-top: 1px solid $one-color-blue-500;
  border-left: 1px solid $one-color-blue-500;
  width: 100%;
  box-sizing: border-box;
}

// <svg>
svg {
  pointer-events: none; // prevent showing svg title on hovering
}

// Used on home page and search page
.link-container:link,
.link-container:visited,
.link-container:hover,
.link-container:active,
.link-container:focus {
  display: block;
  height: 100%;
  text-decoration: none;
  color: inherit;
  cursor: initial;
  outline: 0;
}
