@import '@one/design-tokens/scss/cobas/light/variables';

// ---- Custom style for OWC table -----

owc-table {
  box-shadow: none;
  /**
  * To set the left alignment with header for table w/o selectable row.
  * Ref: https://code.roche.com/onedesign/onedesign-kit/-/issues/77
  */
  &.table-without-checkbox {
    owc-table-header-cell:first-of-type,
    owc-table-row > owc-table-cell:first-of-type {
      padding-left: $one-spacer-16;
    }
  }

  /**
  * To set the min-height so that loading indicator is visible.
  * Ref: https://overflow.roche.com/t/owc-table-queries-for-sort-icon-loader-opacity-text-clipping/13444/3?u=jigneshkumar_parekh
  */
  .owc-table-loader {
    min-height: $one-sizer-base-300;
    margin-top: $one-spacer-48;
  }

  [slot='header'] {
    padding: $one-spacer-16;

    label {
      font-size: $one-text-font-size-px-20;
    }
  }

  owc-table-body {
    font-size: $one-text-font-size-px-14;
  }
}
