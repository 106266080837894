owc-typography {
  &.required-owc-dropdown-hint {
    padding-left: $one-spacer-16;
    color: var(--one-color-interaction-default-neutral-3);
    line-height: $one-spacer-20;
  }
  &.required-owc-dropdown-error {
    padding-left: $one-spacer-16;
    color: var(--one-color-interaction-default-negative-1);
    line-height: $one-spacer-20;
  }
}
