@import 'src/styles/one-design/variables';

//red spot for icon
.red-spot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: $one-color-red-a-300;
  position: relative;
}

.reclassified-dot-position {
  left: 13px;
  top: -12px;
}

.filter-change-dot-position {
  left: 15px;
  top: -14px;
}

.pin-dot-position {
  top: -22px;
  left: 15px;
}

.pin-remove-dot-position {
  top: -21px;
  left: 15px;
}
